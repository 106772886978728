<template>
  <div>
    <div class="breadcrumb">
      <div style="font-size: 14px">
        <span>{{ mySearchBread1 }}</span>
        <span style="margin: 0 5px">-</span>
        <span>{{ mySearchBread2 }}</span>
      </div>
      <div style="padding: 5px">
        <i class="el-icon-close icon" @click="closeClick"></i>
      </div>
    </div>

    <!-- 欠款详情 -->
    <div v-if="isSearchBread">
      <div class="tableInput">
        <span style="font-size: 14px">日期范围：</span>
        <el-date-picker
          type="date"
          v-model="dateTime.date1"
          style="width: 12%"
        ></el-date-picker>
        <span style="margin: 0 5px">-</span>
        <el-date-picker
          type="date"
          v-model="dateTime.date2"
          style="width: 12%"
        ></el-date-picker>
        <el-input
          v-model="QueryContent"
          placeholder="输入订单编号或备注"
          style="width: 20%; margin: 0 5px 0 15px"
        ></el-input>
        <div style="display: flex">
          <el-button type="success"
            ><i class="el-icon-search"></i>查询</el-button
          >
          <el-button type="primary"
            ><i class="iconfont icon-daochu">导出报表</i></el-button
          >
        </div>
      </div>
      <div style="padding: 20px">
        <el-table
          ref="multipleTable"
          border
          :data="tableData1"
          tooltip-effect="dark"
          style="width: 100%; border: 1px solid #e5e5e5"
        >
          <el-table-column prop="creatDate" label="日期" align="center">
          </el-table-column>
          <el-table-column prop="beforeMoney" label="修改前金额" align="center">
          </el-table-column>
          <el-table-column prop="money" label="修改金额" align="center">
          </el-table-column>
          <el-table-column prop="afterMoney" label="修改后金额" align="center">
          </el-table-column>
          <el-table-column prop="remark" label="备注" align="center">
          </el-table-column>
          <el-table-column prop="user" label="操作人" align="center">
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <i
                class="el-icon-edit"
                style="cursor: pointer"
                @click="handleEdit(scope.$index, scope.row)"
              ></i>
              <span style="margin: 0 10px"></span>
              <i
                class="el-icon-delete"
                style="cursor: pointer"
                @click="handleDelete(scope.$index, scope.row)"
              ></i>
            </template>
          </el-table-column>
        </el-table>
        <div class="tableBottom">
          <span v-if="isResult" style=""
            >显示第&nbsp;1&nbsp;至&nbsp;{{
              tableFinshNum
            }}&nbsp;项结果，共&nbsp;{{ tableFinshNum }}&nbsp;项</span
          >
          <span v-if="!isResult" style=""
            >显示&nbsp;{{ tableFinshNum }}&nbsp;项结果，共&nbsp;{{
              tableFinshNum
            }}&nbsp;项</span
          >
          <div
            style="
              display: flex;
              justify-content: flex-end;
              align-items: center;
            "
          >
            <el-button>首页</el-button>
            <el-button>上页</el-button>
            <el-pagination
              background
              layout=" pager,slot"
              :total="1"
            ></el-pagination>
            <el-button>下页</el-button>
            <el-button>末页</el-button>
          </div>
        </div>
      </div>
    </div>

    <!-- 桶详情 -->
    <div v-if="!isSearchBread">
      <div class="tableInput">
        <span style="font-size: 14px">选择品牌：</span>
        <el-select
          v-model="selectStation"
          clearable
          filterable
          placeholder="选择品牌"
          style="width: 15% !important; margin: 0 5px"
        >
          <el-option
            v-for="item in selectStationes"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
        <div style="display: flex">
          <el-button type="success" icon="el-icon-search">查询</el-button>
          <el-button type="primary" icon="iconfont icon-daochu"
            >导出报表</el-button
          >
        </div>
      </div>
      <div style="padding: 20px">
        <el-table
          ref="multipleTable"
          border
          :data="tableData2"
          tooltip-effect="dark"
          style="width: 100%; border: 1px solid #e5e5e5"
        >
          <el-table-column prop="id" label="ID" align="center">
          </el-table-column>
          <el-table-column prop="brand" label="品牌" align="center">
          </el-table-column>
          <el-table-column prop="underbarrel" label="欠桶" align="center">
          </el-table-column>
          <el-table-column prop="cask" label="押桶" align="center">
          </el-table-column>
          <el-table-column prop="unitPrice" label="押桶单价" align="center">
          </el-table-column>
          <el-table-column prop="price" label="押桶价" align="center">
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <i
                class="el-icon-edit"
                style="cursor: pointer"
                @click="handleEdit(scope.$index, scope.row)"
              ></i>
              <span style="margin: 0 10px"></span>
              <i
                class="el-icon-delete"
                style="cursor: pointer"
                @click="handleDelete(scope.$index, scope.row)"
              ></i>
            </template>
          </el-table-column>
        </el-table>
        <div class="tableBottom">
          <span v-if="isResult" style=""
            >显示第&nbsp;1&nbsp;至&nbsp;{{
              tableFinshNum
            }}&nbsp;项结果，共&nbsp;{{ tableFinshNum }}&nbsp;项</span
          >
          <span v-if="!isResult" style=""
            >显示&nbsp;{{ tableFinshNum }}&nbsp;项结果，共&nbsp;{{
              tableFinshNum
            }}&nbsp;项</span
          >
          <div
            style="
              display: flex;
              justify-content: flex-end;
              align-items: center;
            "
          >
            <el-button>首页</el-button>
            <el-button>上页</el-button>
            <el-pagination
              background
              layout=" pager,slot"
              :total="1"
            ></el-pagination>
            <el-button>下页</el-button>
            <el-button>末页</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["searchBread1"],
  data() {
    return {
      dateTime: { date1: "", date2: "" },
      QueryContent: "",

      tableData1: [],
      tableData2: [],
      isResult: false,
      tableFinshNum: 0,

      isSearchBread: -1,
      selectStation: "",
      selectStationes: [],
    };
  },
  mounted() {
    if (this.tableData1.length == 0) {
      this.isResult = false;
    } else {
      this.isResult = true;
      this.tableFinshNum = this.tableData1.length;
    }
    this.$nextTick(() => {
      if (this.mySearchBread2 == "欠款详情") {
        this.isSearchBread = 1;
      } else {
        this.isSearchBread = 0;
      }
    });
  },
  computed: {
    mySearchBread1() {
      return this.searchBread1.value1;
    },
    mySearchBread2() {
      return this.searchBread1.value2;
    },
  },
  methods: {
    closeClick() {
      this.$emit("close", 0);
    },
    handleEdit() {},
    handleDelete() {},
  },
  watch: {},
};
</script>
<style scoped lang="less">
.icon {
  cursor: pointer;
}

.icon:hover {
  color: red;
}

.breadcrumb {
  height: 40px;
  line-height: 40px;
  box-sizing: content-box;
  border-bottom: 1px solid #e5e5e5;
  padding: 0 20px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tableInput {
  height: 31px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #e5e5e5;
}
.tableBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666;
  font-size: 14px;
}
</style>
